@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Poppins&family=Red+Hat+Text&display=swap");

.main-page {
  background-color: #fff4ee;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mob {
  text-align: center;
  font-family: "Major Mono Display", monospace;
  font-size: 36px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.questions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questions h1 {
  font-weight: 600;
  font-size: 25px;
  align-self: flex-start;
  padding-left: 7vw;
  padding-top: 10px;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 50px;
}

.url-container {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 50px;
}

button {
  text-align: center;
  font-size: 20px;
  font-family: "Poppins";
}

img {
  padding-top: 100px;
  padding-right: 5px;
}

p {
  text-align: center;
  align-items: center;
}

#rounded-edge1 {
  text-align: center;
  border-radius: 35px;
  border: 2px solid #e4a0b0;
  padding: 20px;
  width: 300px;
  height: 10px;
}

#rounded-edge2 {
  text-align: center;
  border-radius: 35px;
  border: 2px solid #cfd992;
  background: #cfd992;
  width: 140px;
  height: 40px;
}

#rounded-tan {
  border-radius: 30px;
  border: 1px solid #f1dac5;
  margin: 8px;
  padding: 20px;
  width: 80vw;
  font-family: "Red Hat Text";
}

#rounded-green {
  border-radius: 25px;
  border: 2px solid #cfd992;
  padding: 20px;
  width: 80vw;
  margin: 14px;
  /* width: 800px;
  height: 300px; */
}

#rounded-green h4,
#rounded-red h4 {
  margin-top: 0;
}

#rounded-red {
  border-radius: 30px;
  border: 1px solid #e4a0b0;
  margin: 14px;
  padding: 20px;
  width: 80vw;
  /* width: 800px;
  height: 300px; */
}

#red-btn {
  border-radius: 45px;
  border: 2px solid #e4a0b0;
  padding: 20px;
  width: 275px;
  height: 100px;
  float: left;
}
